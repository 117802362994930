import React from 'react'
import styled from 'styled-components';
import { CustomAnchor } from '../components/CustomLink';
import Layout from '../layouts';

import { TopicHeader } from './work';

const Influences = styled.div`
  padding-top: 2rem;
  font-size: 1.1rem;

  & > p {
    margin-left: 1rem;
    display: flex;
    & > :nth-child(1) {
      flex: 4;
    }
    & > :nth-child(2) {
      flex: 1;
    }

    & > iframe {
      margin: 1rem;
    }
  }

  & > p:nth-child(2n) {
    // border: solid 3px green;
  }
`;

const IndexPage = () => (
  <Layout data={{
    site: {
    siteMetadata: {
      title: 'Rustam Kasad',
      description: 'Music and influences'
    }
    }
    }}>
    <Influences className="">
      <TopicHeader>
        Band
      </TopicHeader>
      <p>
        <span>
        I played in a band called <CustomAnchor url="https://soundcloud.com/exchameleon" text="Ex Chameleon" />. We mostly played indie-rock, jazz, and some funk.
        </span>
      </p>
      <TopicHeader>
         THEORY and PRACTICE 
      </TopicHeader>
      <p>
        <span>
        I built a <CustomAnchor url="https://unostring.com" text="simple web application, called UNOstring" />. which provides a simple exercise to help guitarists practice modal improvisation. This site was inspired by Mick Goodrick's excellent book, <b>The Advancing Guitarist</b>.
        </span>
      </p>
      <TopicHeader>
        INFLUENCES 
      </TopicHeader>
      <p>
        <span>
        If you've come this far, and you've committed yourself (fully) to the Boognish, please consider painting the town brown and enjoy the soothing sounds of Ween. 
        </span>
        <iframe 
          title="Ween - What Deaner Was Talking About (Peel Session)" 
          width="300" height="200" 
          src="https://www.youtube.com/embed/O4jhU0oRVEY" 
          frameborder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen></iframe>
      </p>
      <p>
        <iframe 
          title="Guided By Voices - I am a Scientist" 
          width="300" height="200" 
          src="https://www.youtube.com/embed/0-2k3hlmlDE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        The club is open. Take a listen to Guided By Voices, one of the most prolific rock bands of all time.
      </p>
      <p>
        Medeski, Martin and Wood (MMW) are a funky jazz band from NYC. No further explanation needed.
        <iframe 
          title="Medeski, Martin and Wood - Live"
          width="560" 
          height="315" src="https://www.youtube.com/embed/-3iyMC5nhfw" 
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </p>
    </Influences> 
  </Layout>
)

export default IndexPage
