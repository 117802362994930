import React from 'react';
import styled from 'styled-components';

import { Black } from './colors';

const Container = styled.div`
  padding: .5rem;
`;

const StyledTile = styled.div`
  background-color: white; 
  border-radius: 5px;

  padding: .75rem;
  color: ${Black};
  font-size: 1.6rem;
  font-family: 'Karla', 'Times New Roman', serif !important;
  // font-weight: 600;
  text-align: left;
  vertical-align: baseline; 

  & > p {
    font-size: 1.1rem;
  }
`;

const Tile = ({ children, className }) => (
  <Container 
    className={className}
  >
    <StyledTile>
      {children}
    </StyledTile>
  </Container>
);

export default Tile;
