import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { 
  LonelyBlue, 
  YellowHand, 
  StationaryPinkRed
} from '../components/colors';


const commonStyle = css`
  z-index: 100;
  color: ${LonelyBlue};

  padding: .2rem;
  border-radius: 5px;
  text-decoration: none;
  background-color: ${YellowHand};
  &:hover {
    color: ${StationaryPinkRed};
  }

`;

const StyledAnchor = styled.a`
  ${commonStyle};
`;

const StyledLink = styled(Link)`
  ${commonStyle};
`;

const CustomLink = ({ text, path, customFont }) => (
  <StyledLink 
    to={path}
  >
    {text}
  </StyledLink>
);

export const CustomAnchor = ({ text, url }) => (
  <StyledAnchor 
    href={url}
  >
    {text}
  </StyledAnchor>
);

export const IndexLink = ({ text, path }) => (
  <StyledLink 
    customFont={true}
    to={path}
  >
    {text}
  </StyledLink>
);

export default CustomLink;
