import React from 'react'
import styled from 'styled-components';
import Tile from '../components/Tile';
import Layout from '../layouts/';
import { CustomAnchor } from '../components/CustomLink';
import jsBindPdf from './bind-with-js.pdf';

export const TopicHeader = styled.h3`
  margin-top: 1.5rem;
  text-transform: uppercase;
  font-family: "Compacta Bold", Courier;
  -webkit-text-stroke: 1px black;
  letter-spacing: .1rem;

  font-size: 3rem;
  color: white;
  margin-bottom: .25rem;
  padding-top: .5rem;
  border-bottom: solid 2px black;
`;

const Work = () => (
  <Layout data={{
    site: {
    siteMetadata: {
      title: 'Rustam Kasad',
      description: 'Work and professional'
    }
    }
    }}>
    <Tile>
      <TopicHeader>bio</TopicHeader>
      <p>
        I'm a software engineer experienced with Java, .NET/C#, and Javascript, 
        I occasionally write about software development 
        at <CustomAnchor url="https://codeacapella.com" text="Code Acapella" />. 

      </p>
      <p>
        My current skillset:
        <ul>
          <li>Front-end development with React, GatsbyJS, Typesccript</li>
          <li>Back-end development with NodeJS, Java, C#/.NET</li>
          <li>
            Building web applications and distributed systems with Netlify, AWS and Docker.
          </li>
          <li>
            A preternatural affinity for Git.
          </li>
        </ul>
       </p>
       <TopicHeader>hobby</TopicHeader>
       <p>
         I have been learning Clojure over the past few years and will eventually 
         eventually write generative music in a Clojure-based domain-specific language called <CustomAnchor url="https://blog.djy.io/alda-a-manifesto-and-gentle-introduction/" text="alda"/>.
      </p>
       <p>
       { false &&
       <div>SVGs, Sarah Drasner's site, Project Gutenberg, Music chords</div>
       }
       </p>
       <TopicHeader>Talks and presentations</TopicHeader>
       <p>
         In Feburary of 2017, I gave a <CustomAnchor 
           url="https://www.sandiegojs.org/event/2017-02-monthly" 
           text="lightning talk on how function binding works with the `this` keyword" 
         /> in Javascript at the San Diego JS meetup. 
           You can see my <CustomAnchor url={jsBindPdf} text="presentation notes here" />. Looking forward to giving more presentations in the future!
       </p>
    </Tile>
  </Layout>
);

export default Work;
